import { ChangeEvent, useState } from 'react';

const URL = `/api/lets-talk`;

type LetsTalkFormData = {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
};

const validateEmail = (email: string): boolean => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const defaultFormData: LetsTalkFormData = {
  firstName: '',
  lastName: '',
  email: '',
  subject: '',
  message: '',
};

const validate = (data: LetsTalkFormData): LetsTalkFormData => ({
  firstName: !data.firstName ? 'First name is required' : '',
  lastName: !data.lastName ? 'Last name is required' : '',
  email: !data.email ? 'Email is required' : !validateEmail(data.email) ? 'Email is invalid' : '',
  subject: !data.subject ? 'Subject is required' : '',
  message: !data.message ? 'Message is required' : '',
});

const isValid = (validationResult: LetsTalkFormData): boolean => {
  return Object.values(validationResult).every((field) => !field);
};

interface UseLetsTalkForm {
  onChange: (e: ChangeEvent<any>) => void;
  formData: LetsTalkFormData;
  error: LetsTalkFormData;
  onSubmit: () => Promise<boolean>;
  successMessage: string;
  errorMessage: string;
  loading: boolean;
}

const useLetsTalkForm = (): UseLetsTalkForm => {
  const [formData, setFormData] = useState<LetsTalkFormData>(defaultFormData);
  const [error, setError] = useState<LetsTalkFormData>(defaultFormData);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<any>): void => {
    setErrorMessage('');
    setError({
      ...error,
      [e.target.name]: undefined,
    });
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit: UseLetsTalkForm['onSubmit'] = async () => {
    setLoading(true);
    const validateResult = validate(formData);
    if (isValid(validateResult)) {
      try {
        await fetch(URL, {
          method: 'POST',
          body: JSON.stringify(formData),
        });
        setFormData(defaultFormData);
        setSuccessMessage('Thanks for contacting us!\nWe will be in touch with you shortly.');
      } catch (e) {
        setErrorMessage('Something went wrong!');
        console.error(e);
      } finally {
        setLoading(false);
      }
      return true;
    } else {
      setError(validateResult);
      setLoading(false);
      return false;
    }
  };

  return {
    onChange,
    formData,
    error,
    onSubmit,
    successMessage,
    errorMessage,
    loading,
  };
};

export default useLetsTalkForm;
