import classNames from 'classnames';

import Button from '@/components/button';
import useLetsTalkForm from '@/hooks/useLetsTalkForm';

import styles from './lets-talk.module.scss';

export const LetsTalk = () => {
  const { formData, onChange, onSubmit, error, successMessage, loading } = useLetsTalkForm();
  return (
    <div className={styles.layout}>
      <div className={styles.background}></div>
      {!successMessage ? (
        <>
          <div id="let-talk" className={styles.container}>
            <div className={styles.title}>Let&apos;s Talk</div>
            <div className={styles.description}>We can help you to achieve results.</div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              className={styles.form}
            >
              <div className={styles.row}>
                <div className={styles.half}>
                  <input
                    value={formData.firstName}
                    onChange={onChange}
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    className={classNames({
                      [styles.error]: !!error.firstName,
                    })}
                  />
                </div>
                <div className={styles.half}>
                  <input
                    value={formData.lastName}
                    onChange={onChange}
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    className={classNames({
                      [styles.error]: !!error.lastName,
                    })}
                  />
                </div>
              </div>
              <input
                value={formData.email}
                onChange={onChange}
                type="email"
                placeholder="Your email"
                name="email"
                className={classNames({
                  [styles.error]: !!error.email,
                })}
              />
              <input
                value={formData.subject}
                onChange={onChange}
                className={classNames({
                  [styles.error]: !!error.email,
                })}
                type="text"
                placeholder="Subject"
                name="subject"
              />
              <textarea
                value={formData.message}
                onChange={onChange}
                className={classNames({
                  [styles.error]: !!error.email,
                })}
                placeholder="Your Message"
                name="message"
              />
              <Button loading={loading} type="submit">
                SEND MESSAGE
              </Button>
            </form>
          </div>
        </>
      ) : (
        <div className={styles.successMessage}>{successMessage}</div>
      )}
    </div>
  );
};

export default LetsTalk;
