import { BuilderComponent } from '@builder.io/react';
import { FC, Fragment } from 'react';

import DefaultLayout from '@/components/layout';
import MetaBlock from '@/components/meta-block/MetaBlock';
import { BUILDER_MODEL } from '@/types/builder.d';
import { getBlockDataMap } from '@/utils/Builder';

interface PageProps {
  page: any;
}

const Page: FC<PageProps> = ({ page }) => {
  const renderContent = () => (
    <BuilderComponent model={BUILDER_MODEL.PAGE} content={page} data={getBlockDataMap(page)} />
  );

  const Layout = page.data.hideLayout ? Fragment : DefaultLayout;

  return (
    <>
      <MetaBlock title={page?.data?.title} description={page?.data?.description} />
      <Layout hideFooter={page.data.hideFooter}>{renderContent()}</Layout>
    </>
  );
};

export default Page;
