import { FC } from 'react';

import LetsTalk from '@/modules/lets-talk';
import Navigation from '@/modules/navigation';

import styles from './layout.module.scss';

interface DefaultLayoutProps {
  children?: React.ReactNode;
  hideFooter?: boolean;
}

const DefaultLayout: FC<DefaultLayoutProps> = (props) => {
  const { hideFooter, children } = props;

  return (
    <div className={styles.container}>
      <Navigation />
      <div className={styles.contentContainer}>{children}</div>
      {hideFooter ? null : <LetsTalk />}
    </div>
  );
};

export default DefaultLayout;
