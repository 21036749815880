import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useState } from 'react';

import Icon, { IconType } from '@/components/Icon';
import { useWindowSize } from '@/hooks/useWindowSize';
import { BLOG_HOME_PAGE } from '@/shared/constant';
import { NavigationContext } from '@/shared/NavigationContext';

import styles from './navigation.module.scss';

export interface NavigationProps {
  onLetTalkClick?: () => void;
  letTalkLabel?: string;
  fixed?: boolean;
}

const Navigation: FC<NavigationProps> = ({
  onLetTalkClick,
  letTalkLabel = "Let's talk?",
  fixed,
}) => {
  const { theme } = useContext(NavigationContext);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [needCloseMobileMenu, setNeedCloseMobileMenu] = useState(false);
  const { width = 0 } = useWindowSize();
  const router = useRouter();
  const [isBlogHomePage, setIsBlogHomePage] = useState(false);

  useEffect(() => {
    const slug = router.query.slug as any;
    const pageSlug = Array.isArray(slug) ? slug.join('/') : slug;

    setIsBlogHomePage(`/${pageSlug}` === BLOG_HOME_PAGE);
  }, [router]);

  useEffect(() => {
    if (width < 1024) {
      if (needCloseMobileMenu) {
        setNeedCloseMobileMenu(false);
        setIsMobileMenuActive(false);
      }
    } else {
      setNeedCloseMobileMenu(true);
      if (isMobileMenuActive) {
        setIsMobileMenuActive(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    const body = document.body;
    if (!body) {
      return;
    }

    if (isMobileMenuActive) {
      body.classList.add('lock-scroll');
    } else {
      body.classList.remove('lock-scroll');
    }
  }, [isMobileMenuActive]);

  return (
    <div
      className={classNames(styles.container, styles[theme], {
        [styles.active]: isMobileMenuActive,
        [styles.fixed]: fixed,
      })}
    >
      <div className={styles.logo}>
        <Link href={'/'} passHref>
          <a aria-label="Dijitally Logo">
            <Icon type={IconType.Dijitally} />
          </a>
        </Link>
      </div>
      <div className={classNames(styles.items)}>
        <a
          href={BLOG_HOME_PAGE}
          className={classNames(styles.item, { [styles.active]: isBlogHomePage })}
        >
          Articles
        </a>
        <div
          onClick={() => {
            setIsMobileMenuActive(false);
            if (onLetTalkClick) {
              onLetTalkClick();
            } else {
              setTimeout(() => {
                document.getElementById('let-talk')?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 100);
            }
          }}
          className={styles.item}
        >
          {letTalkLabel}
        </div>
      </div>
      <div
        className={classNames(styles.hamburgerLines, { [styles.active]: isMobileMenuActive })}
        onClick={() => setIsMobileMenuActive((val) => !val)}
      >
        <span className={classNames(styles.line, styles.line1)}></span>
        <span className={classNames(styles.line, styles.line2)}></span>
        <span className={classNames(styles.line, styles.line3)}></span>
      </div>
    </div>
  );
};

export default Navigation;
